import * as React from "react"
import Layout from "../../../../../components/layout";
import * as styles from "../../../notification.module.css"

const NotificationKanjinumbercrosswordAndroid20230104Page = () => {
    return (
        <Layout isTop={false}>
            <article>
                <h1>2023年1月4日の「今日の1問」の問題について</h1>
                <p>2023年1月4日の「今日の1問」の問題について、「青息吐息」とすべき箇所が、誤って「青息戸息」となっていました。<br/>
                    以下のリンクから、アプリを最新版に更新してください。</p>
                <a href="https://play.google.com/store/apps/details?id=info.nullhouse.kanjinumbercrossword">https://play.google.com/store/apps/details?id=info.nullhouse.kanjinumbercrossword</a>
            </article>
        </Layout>
    )
}

export default NotificationKanjinumbercrosswordAndroid20230104Page